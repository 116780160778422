import { createSlice } from '@reduxjs/toolkit';
// types
import { IOpenBetItem } from 'src/types/sport';

interface ExchangeState {
  profitLoss: any;
  openbets: IOpenBetItem[];
}

const initialState: ExchangeState = {
  profitLoss: {},
  openbets: [],
};

export const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    setProfitLoss(state, action) {
      state.profitLoss = { ...state.profitLoss, ...action.payload };
    },
    setOpenBets(state, action) {
      state.openbets = action.payload;
    },
    clearProfitLoss(state) {
      state.profitLoss = {};
    },
  },
});

export const { setProfitLoss, setOpenBets, clearProfitLoss } = exchangeSlice.actions;

export default exchangeSlice.reducer;
