import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { RootState } from 'src/redux/rootReducer';
import { handleLogin, handleLogout } from 'src/redux/authentication';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const state = useSelector((rootState: RootState) => rootState.auth);
  const dispatch = useDispatch();

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const res = await axios.post(endpoints.auth.me);

        const { user } = res.data;
        dispatch(handleLogin(user));
      } else {
        dispatch(handleLogout());
      }
    } catch (error) {
      dispatch(handleLogout());
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (username: string, password: string) => {
      const data = {
        username,
        password,
      };

      const res = await axios.post(endpoints.auth.login, data);

      const { accessToken, user } = res.data;

      setSession(accessToken);

      dispatch(handleLogin(user));
    },
    [dispatch]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      const response = await axios.post(endpoints.auth.logout);
      if (response.data) {
        setSession(null);
        dispatch(handleLogout());
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.authLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
