// ** Reducers Imports
// import navbar from "./navbar";
import { combineReducers } from '@reduxjs/toolkit';
import auth from './authentication';
import users from './users';
import exchange from './exchange';
// import navdata from "./navdata";

const rootReducer = combineReducers({
  auth,
  users,
  // navbar,
  exchange,
  // navdata,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
