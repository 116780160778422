// ** Redux Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRole, IUser } from 'src/types/user';

interface UsersState {
  roles: IRole[];
  selectedUser: IUser | null;
}

const initialState: UsersState = {
  roles: [],
  selectedUser: null,
};

export const authSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateRoles: (state, action: PayloadAction<IRole[]>) => {
      state.roles = action.payload;
    },
    selectUser: (state, action: PayloadAction<IUser>) => {
      state.selectedUser = action.payload;
    },
  },
});

export const { updateRoles, selectUser } = authSlice.actions;

export default authSlice.reducer;
