// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';
import { IAuthUser, IBalane } from 'src/types/user';

interface AuthState {
  authLoading: boolean;
  user: IAuthUser | null;
  myId: string | null;
  balance: IBalane;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  authLoading: true,
  user: null,
  myId: null,
  balance: {
    main: 0,
    available: 0,
    reference: 0,
    profit: 0,
    bonus: 0,
    widrwalbleBonus: 0,
    exposure: 0,
    pending: 0,
    withdrawable: 0,
    withdrawableBonus: 0,
    pokerExposure: 0,
  },
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    updateAccount: (state, action) => {
      state.user = action.payload;
    },
    updateBalance: (state, action) => {
      state.balance = action.payload;
    },
    handleLogin: (state, action) => {
      state.user = action.payload;
      state.balance = action.payload.balance;
      state.myId = action.payload._id;
      state.isAuthenticated = true;
      state.authLoading = false;
    },
    handleLogout: (state) => {
      state.user = null;
      state.myId = null;
      state.balance = initialState.balance;
      state.isAuthenticated = false;
      state.authLoading = false;
      localStorage.removeItem('accessToken');
    },
  },
});

export const { handleLogin, handleLogout, updateAccount, updateBalance } = authSlice.actions;

export default authSlice.reducer;
