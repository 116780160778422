import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io, { Socket } from 'socket.io-client';
// redux
import { RootState } from 'src/redux/rootReducer';
import { handleLogout, updateBalance } from 'src/redux/authentication';
// components
import { useSnackbar } from 'src/components/snackbar';
// config
import { HOST_API } from 'src/config-global';
//
import { SocketContext } from './socket-context';

type Props = {
  children: React.ReactNode;
};

const SocketProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);
  const [socket, setSocket] = useState<Socket | null>(null);

  const registerCallbacks = (connect: Socket) => {
    if (user) {
      connect.on('destory', (dd) => {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && dd.data[user._id] === accessToken) {
          enqueueSnackbar('Your session expired!', { variant: 'error' });
          dispatch(handleLogout());
        }
      });

      connect.on('balance', (data) => {
        if (data[user._id]) {
          dispatch(updateBalance(data[user._id]));
        }
      });

      connect.on('logout', (data) => {
        if (data[user._id]) {
          dispatch(handleLogout());
        }
      });

      // socket.on("navbar", (data) => {
      //   if (data[user._id]) {
      //     dispatch(socketPaymentCount(data[user._id]));

      //     let redirectUrl = "";
      //     if (data[user._id].type === "deposit") {
      //       redirectUrl = `${window.location.origin}/manual-deposit`;
      //     } else if (data[user._id].type === "withdraw") {
      //       redirectUrl = `${window.location.origin}/withdraw`;
      //     } else if (data[user._id].type === "kyc") {
      //       redirectUrl = `${window.location.origin}/reports/kyc`;
      //     }
      //     checkPageStatus(
      //       data[user._id].message,
      //       logo,
      //       redirectUrl,
      //       data[user._id].type
      //     );
      //   }
      // });
    }
  };

  const cleanUp = useCallback(() => {
    setSocket(null);
  }, []);

  const connect = (query: { [key: string]: any }) => {
    const socketConnection = io(HOST_API, {
      transports: ['websocket'],
      upgrade: false,
      query,
    });
    registerCallbacks(socketConnection);
    setSocket(socketConnection);
    return socketConnection;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', cleanUp);
    window.addEventListener('beforeclose', cleanUp);
    return () => cleanUp();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    console.log(token);
    if (isAuthenticated && token) {
      connect({ auth: token });
    }
    return () => cleanUp();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const memoizedValue = useMemo(
    () => ({
      socket,
      //
      cleanUp,
    }),
    [socket, cleanUp]
  );

  return <SocketContext.Provider value={memoizedValue}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
