import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API, ASSETS_SERVER_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const ASSETS = (param: string) => `${ASSETS_SERVER_API}/${param}`;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  user: '/api/users',
  sport: '/api/sports',
  dashboard: '/api/dashboard',
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/adminLogin',
    register: '/api/auth/register',
    logout: '/api/auth/logout',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  domain: '/api/domain',
  kyc: '/api/kyc',
  payment: '/api/payment',
  provider: '/api/provider',
  st8: '/api/st8',
  setting: '/api/settings',
  banner: '/api/banner',
  manualPayment: '/api/manual-payment',
  spribe: '/api/spribe',
};
